@mixin button($color, $bk_color) {
  color: $color;
  font-weight: bold;
  background-color: $bk_color;
  padding: 10px 40px;
  border: 0;
  border: 0;

  &:hover {
    opacity: .8;
    color: $color;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    outline:0;
  }
}

.button-default {
  @include button(white, $texts_color);
}

.button-white {
  @include button($texts_color, white);
}

.button-green {
  @include button($primary_color, transparent);
  border: 4px solid $primary_color;
  &:hover {
    color: white;
    background-color: $primary-color;
    opacity: initial;
  }
}

.button-green-dark {
  @include button(white, $primary_color);
  border: 2px solid $primary_color;
  &:hover {
    background-color: $nature_color;
    border: 2px solid $nature_color;
    opacity: initial;
  }
}