@import 'base/_variables';
@import 'components/_buttons';

* {
  font-family: 'Open Sans', sans-serif;
}

.not-found-image-container {
  height: 100%;
  margin: 0;
  padding: 0;
}

.not-found-image {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.not-found-text {
  text-align: center;
}

.not-found-text * {
  opacity: 1;
}

.not-found-title {
  margin-bottom: 1em;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 20px;
  color: white;
  font-weight: 700;
  margin-bottom: 0;
}
